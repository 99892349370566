import React from 'react'

export default function Cta1() {
  return (
    <>
    <section className="talk2expert mt-5">
        <div className="container-xl pb-3 pb-lg-0">
            <div className="row">
                <div className="col-md-6">
                    <div className="cta-1-img">
                        <img src="./assets/img/anchor.webp" width="80%" alt=""/>
                        <img src="./assets/img/chat.webp" width="30%" className="chat-img" alt=""/>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center flex-column px-3">
                    <p className="fs-1 mb-5">Receive Genuine Guidance and Insights from Accomplished Students</p>
                    <p className="fs-4">Take the leap and connect with students who have already embarked on the journey you aspire to take</p>
                   <a href="javascript:void(0);" className="primary-btn px-4 py-3 rounded-pill">Talk to expert
                   </a>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
