import React from 'react'

export default function Reviews() {
  return (
    <>
            <section>
    <div className="container-fluid rv-section py-5">
        <div className="container">
            <div className="row gap-4 justify-content-center">
                <div className="col-sm-4 rv-card">
                    <div className="d-flex mb-4">
                        <div>
                            <h2 className="fs-1 fw-bolder">4.5</h2>
                            <p className="fs-1 lh-sm">Google Review</p>
                        </div>
                        <div>
                            <img className="rv-image" src="./assets/img/google-logo.webp" width="100px" alt=""/>
                        </div>
                        
                    </div>
                    <span className="text-warning fs-4">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-regular fa-star-half-stroke"></i>
                    </span>
                </div>
                <div className="col-sm-4 rv-card">
                    <div className="d-flex mb-4">
                        <div>
                            <h2 className="fs-1 fw-bolder">4.5</h2>
                            <p className="fs-1 lh-sm">Facebook Review</p>
                        </div>
                        <div>
                            <img className="rv-image" src="./assets/img/facebook.webp" width="100px" alt=""/>
                        </div>
                        
                    </div>
                    <span className="text-warning fs-4 ">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-regular fa-star-half-stroke"></i>
                    </span>
                </div>
            </div>
            <p className="fs-3 text-center mt-5">Garnering the trust of over a million students Worldwide, our platform stands as a beacon for comprehensive academic guidance. Join us on a journey of success and transformation!</p>
            <div className="d-flex justify-content-center mt-5">
                <a href="<?php echo site_url(); ?>form" className="primary-btn px-4 py-3 rounded-pill">Get Started</a>
            </div>
        </div>
    </div>
    </section>
    </>
    )
}
