import React from 'react'

export default function () {
  return (
    <>
    <section>
        <div className="cta-banner p-2">
            <div className="container cta-content mt-5 mb-lg-5 mb-3 py-2 py-lg-5 px-md-5">
                <p className="fs-3 cta-title text-center">Study Buddy - CourseFinder</p>
                <p className="text-center">Navigate Your University Dreams with Precision! Use Our Course Finder to Reveal Your Admission Possibilities</p>
                <p>Embark on your academic journey with our innovative Course Finder tool. It's designed to estimate your admission odds at your dream university, taking the guesswork out of your university application process!</p>
                
                <a href="<?php echo site_url(); ?>form" className="primary-btn px-3 py-2 rounded-pill">Check Probability</a>
            </div>
        </div>
    </section>
    </>
  )
}
