import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogCard(props) {

    console.log(props)

    

    return (
        <div className="box">
            <div className="blog-image">
                <Link to={props.link}>
                    <img src={"./assets/img/"+props.img+".webp"} alt="Countries To Study Abroad In Europe" />
                </Link>
            </div>
            <div className="blog-inner">
                <p className="date">{props.time}</p>
                <h4 className="blog-title"><Link to={props.link}>{props.title}</Link></h4>
                <p className="blog-content">{props.content}</p>
            </div>
        </div>
    )
}
