import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import BlogCard from './BlogCard'


export default function Blog() {

    var [blogs,setBlogs] = useState([])
    async function getBlogData(){
        var response = await fetch("http://localhost:5000/blog",{
            method:"get",
            headers:{
                "content-type":"application/json"
            }
        })
        const data = await response.json()
        if(data.length){
            setBlogs(data)
        }
    }
    useEffect(()=>{
        getBlogData()
    },blogs.length)
    return (
        <>
            <div className="banner" style={{height:"unset"}}>
                <Navbar/>
                <div class="container blog-banner">
                    <h1 class="text-center fw-bolder">Student Helpline Blogs</h1>
                    <p class="fs-5 text-secondary text-center">Education Without Borders -Study Abroad for a Global Perspective</p>
                    <div class="prev-link d-none d-sm-block">
                        <span class="text-orange"><a href="" class="text-orange text-decoration-none">Study Abroad</a> <a
                            href="https://thestudenthelpline.co.in/blog" class="text-orange text-decoration-none">Blog
                        </a></span>
                    </div>
                    <div class="search-box">
                        <form name="search" action="https://thestudenthelpline.co.in/home/search" method="get">
                            <div class="search-input">
                                <button type="submit"><span><i class="fa-solid fa-magnifying-glass"></i></span></button>
                                <input type="text" name="squery" placeholder="Search Your Blog Here"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-xxl mt-10">
                <div class="row">
                    <div class="col-md-6 px-2">
                        <a href="https://thestudenthelpline.co.in/blog/gre-analytical-writing">
                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/GRE_Analytical_Writing.webp"
                                width="100%" alt="Gre Analytical Writing"/>
                        </a>
                        <h2 class="fw-bold fs-4 mt-4">
                            <a href="https://thestudenthelpline.co.in/blog/gre-analytical-writing">
                                GRE Analytical Writing </a>
                        </h2>
                        <p class="my-4">
                            Are you preparing for the GRE and feeling intimidated by the GRE Analytical Writing section? You're not
                            alone! Many test-takers find the prospect of writing two essays under timed conditions daunting. ...
                        </p>
                        <div class="d-flex flex-wrap">
                            <span class="info-orange rounded-pill px-3 py-2 me-2">PUBLISHED BY NIdhi</span>
                            <span class="info-green rounded-pill px-3 py-2">31 Jul 2023</span>
                        </div>
                    </div>
                    <div class="col-md-6 px-4 pt-3">

                        <h4>Featured Article</h4>
                        <div class="article-box border rounded">
                            <a href="https://thestudenthelpline.co.in/blog/benefits-of-the-uk-shorter-degree-programs">
                                <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/shorter-degree-programs-in-uk.webp"
                                    width="110px" height="110px" alt=""/>
                            </a>
                            <div class="ms-3">

                                <h6>
                                    <a href="https://thestudenthelpline.co.in/blog/benefits-of-the-uk-shorter-degree-programs">
                                        The Benefits of the UK’s Shorter Degree Programs </a>
                                </h6>

                                <p class="text-secondary"><small>
                                    What's the best part? You're about to learn how innovative programs like these can help you
                                    advanc ... </small></p>
                            </div>
                        </div>
                        <div class="article-box border rounded">
                            <a href="https://thestudenthelpline.co.in/blog/affordable-countries-to-study-abroad-in-europe">
                                <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/countries-to-study-in-europe.webp"
                                    width="110px" height="110px" alt="Countries To Study Abroad In Europe"/>
                            </a>
                            <div class="ms-3">

                                <h6>
                                    <a href="https://thestudenthelpline.co.in/blog/affordable-countries-to-study-abroad-in-europe">
                                        Affordable Countries to Study Abroad in Europe </a>
                                </h6>

                                <p class="text-secondary"><small>
                                    Be Prepared to be amazed by the possibilities. ... </small></p>
                            </div>
                        </div>
                        <div class="article-box border rounded">
                            <a href="https://thestudenthelpline.co.in/blog/10-ways-to-study-abroad">
                                <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/10-ways-to-studying-in-abroad.webp"
                                    width="110px" height="110px" alt="10 Ways To Study Abroad"/>
                            </a>
                            <div class="ms-3">

                                <h6>
                                    <a href="https://thestudenthelpline.co.in/blog/10-ways-to-study-abroad">
                                        10 Ways to Study Abroad </a>
                                </h6>

                                <p class="text-secondary"><small>
                                    Students in high school and college are more e ... </small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="other-articles">
                <section className="blog">
                    <div class="container">
                        <h2 class="fs-1 fw-bold">Latest Articles</h2>
                        <div className="blog-container">
                            <div className="blog-boxes">
                                {
                                    blogs.map((item,index)=>{
                                       return <BlogCard key={index} link={item.link} img={item.img} title={item.title} time={item.time} content={item.content}/>
                                    })
                                }

                                {/* <div className="box">
                                    <div className="blog-image">
                                        <a href="https://thestudenthelpline.co.in/blog/gre-analytical-writing">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/GRE_Analytical_Writing.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/gre-analytical-writing">GRE Analytical
                                            Writing</a> </h4>
                                        <p className="blog-content">
                                            Are you preparing for the GRE and feeling intimidated by the GRE Analytical Writing
                                            section? You're not alone! Many test-takers find the prospect of writing two essays
                                            under timed ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a
                                            href="https://thestudenthelpline.co.in/blog/construction-management-courses-in-australia">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/construction-management-courses-in-australia.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/construction-management-courses-in-australia">Construction
                                            Management Courses in Australia</a> </h4>
                                        <p className="blog-content">
                                            Australia is one of the most popular study destinations chosen by international students
                                            who wish to study abroad. This country is home to some of the world's top universities
                                            that ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a href="https://thestudenthelpline.co.in/blog/msc-in-geology">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/msc-in-geology-abroad.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a href="https://thestudenthelpline.co.in/blog/msc-in-geology">MSc in
                                            Geology</a> </h4>
                                        <p className="blog-content">
                                            Geology is a dynamic field providing fascinating opportunities for you in the planet's
                                            past, present, and future. Here are some reasons you should pursue an MSc in Geology
                                            abroad:
                                            ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a href="https://thestudenthelpline.co.in/blog/united-kingdom-study-visa-process">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/uk-study-visa-process.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/united-kingdom-study-visa-process">United
                                            Kingdom Study VISA Process</a> </h4>
                                        <p className="blog-content">
                                            Why Studying The UK Is Best For Your Career Growth
                                            International students want to study in the United Kingdom because the country has a
                                            world-renowned education system and a diver ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a href="https://thestudenthelpline.co.in/blog/undergraduate-courses-in-canada">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/undergraduate-course-in-canada.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/undergraduate-courses-in-canada">Undergraduate
                                            Courses in Canada</a> </h4>
                                        <p className="blog-content">
                                            Hello, aspiring undergraduates! Are you considering going to university and ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a href="https://thestudenthelpline.co.in/blog/the-guide-to-studying-abroad">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/study-abroad-a-turning-point-in-your-career.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/the-guide-to-studying-abroad">The Guide
                                            To Studying Abroad</a> </h4>
                                        <p className="blog-content">
                                            This guide answers some frequently asked questions about studying abroad in college.
                                            This guide provides answers to some frequently asked questions about college study
                                            abroad.
                                            You ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a href="https://thestudenthelpline.co.in/blog/10-ways-to-study-abroad">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/10-ways-to-studying-in-abroad.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/10-ways-to-study-abroad">10 Ways to
                                            Study Abroad</a> </h4>
                                        <p className="blog-content">
                                            Students in high school and college are more eager to study abroad than ever ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a
                                            href="https://thestudenthelpline.co.in/blog/affordable-countries-to-study-abroad-in-europe">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/countries-to-study-in-europe.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/affordable-countries-to-study-abroad-in-europe">Affordable
                                            Countries to Study Abroad in Europe</a> </h4>
                                        <p className="blog-content">
                                            Be Prepared to be amazed by the possibilities. You can get a world-class edu ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="blog-image">
                                        <a href="https://thestudenthelpline.co.in/blog/benefits-of-the-uk-shorter-degree-programs">
                                            <img src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/shorter-degree-programs-in-uk.webp"
                                                alt="blog-img"/>
                                        </a>
                                    </div>
                                    <div className="blog-inner">
                                        <p class="date">31 Jul 2023</p>
                                        <h4 className="blog-title"><a
                                            href="https://thestudenthelpline.co.in/blog/benefits-of-the-uk-shorter-degree-programs">The
                                            Benefits of the UK’s Shorter Degree Programs </a> </h4>
                                        <p className="blog-content">
                                            What's the best part? You're about to learn how innovative programs like these can help
                                            you advance your career while saving tim ... </p>
                                        <h6 class="time"><span>03 min read</span></h6>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </section>
                <div class="d-flex justify-content-center w-100">
                    <a href="" class="primary-btn py-3 px-4 rounded-pill fs-4">SEE MORE ARTICLE
                    </a>
                </div>
            </div>
        </>
    )
}
