import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Testimonials() {
    const options = {
    loop: true,
    margin: 10,
    autoplay: true,
    dots: false,
    nav: false,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
            loop: true
        }
    }
      };
    return (
        <>
            <section>
                <div className="container">
                    <div className="tst-header d-flex justify-content-between mb-4">
                        <div>
                            <p className="text-warning fw-600 fs-5 mb-2">Testimonials</p>
                            <h2 className="fs-1 fw-600">Students Choose And Recommend Us</h2>
                        </div>
                        <div className="d-flex align-items-center owl-nav">
                            <button type="button" className="tst-nav me-4 prev" role="presentation"><i className="fa-solid fa-chevron-left"></i></button>
                            <button className="tst-nav next"><i className="fa-solid fa-angle-right"></i></button>
                        </div>
                    </div>

                    <OwlCarousel className='owl-theme' {...options} >
                    <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t3.webp" width="100%" controls>
                                <source src="./assets/video/t3.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Jatin</p>
                                <span><small>With their extensive expertise and unwavering support, your journey to international education will be smooth and rewarding</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t2.webp" width="100%" controls>
                                <source src="./assets/video/t2.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Kansha</p>
                                <span><small>The Student Helpline comes highly recommended. Their comprehensive support and guidance can make your global education dreams a reality</small></span>
                            </div>
                        </div>

                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t6.webp" width="100%" controls>
                                <source src="./assets/video/t6.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Ruchita</p>
                                <span><small>The Student Helpline comes highly recommended. Their comprehensive support and guidance can make your global education dreams a reality</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t1.webp" width="100%" controls>
                                <source src="./assets/video/t1.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Nidhi</p>
                                <span><small>I strongly endorse The Student Helpline for anyone considering studying abroad</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t4.webp" width="100%" controls>
                                <source src="./assets/video/t4.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Kartik</p>
                                <span><small>The Student Helpline boasts an exceptional team with a strong commitment to professionalism and integrity</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t5.webp" width="100%" controls>
                                <source src="./assets/video/t5.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Ritik</p>
                                <span><small>Had it not been for The Student Helpline, I wouldn't have been able to achieve my objectives</small></span>
                            </div>
                        </div>
                    </OwlCarousel>

                    {/* <div className="tst-body owl-carousel owl-theme" id="owlcustom">
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t3.webp" width="100%" controls>
                                <source src="./assets/video/t3.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Jatin</p>
                                <span><small>With their extensive expertise and unwavering support, your journey to international education will be smooth and rewarding</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t2.webp" width="100%" controls>
                                <source src="./assets/video/t2.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Kansha</p>
                                <span><small>The Student Helpline comes highly recommended. Their comprehensive support and guidance can make your global education dreams a reality</small></span>
                            </div>
                        </div>

                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t6.webp" width="100%" controls>
                                <source src="./assets/video/t6.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Ruchita</p>
                                <span><small>The Student Helpline comes highly recommended. Their comprehensive support and guidance can make your global education dreams a reality</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t1.webp" width="100%" controls>
                                <source src="./assets/video/t1.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Nidhi</p>
                                <span><small>I strongly endorse The Student Helpline for anyone considering studying abroad</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t4.webp" width="100%" controls>
                                <source src="./assets/video/t4.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Kartik</p>
                                <span><small>The Student Helpline boasts an exceptional team with a strong commitment to professionalism and integrity</small></span>
                            </div>
                        </div>
                        <div className="tst-frame">
                            <video className="vid" poster="./assets/video/thumb/t5.webp" width="100%" controls>
                                <source src="./assets/video/t5.webm" />
                            </video>
                            <div className="video-controls">
                                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
                            </div>
                            <div className="tst-frame-content">
                                <p className="mb-0 fs-5">Ritik</p>
                                <span><small>Had it not been for The Student Helpline, I wouldn't have been able to achieve my objectives</small></span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}
