import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Mentor() {
  const options ={
    loop: true,
    margin: 20,
    autoplay: true,
    dots: false,
    nav: false,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
            loop: true
        }
    }
  }
  return (
    <>
        <section>
    <div className="container">
          <div className="mentor-x mt-3 mx-4">
          <img src="./assets/img/IMG_6965.webp" alt="" className="d-none d-sm-block plane"/>
          <img src="./assets/img/IMG_6965.webp" alt="" className="d-none d-sm-block plane"/>
          <img src="./assets/img/IMG_6965.webp" alt="" className="d-none d-sm-block plane"/>
          <img src="./assets/img/bg.webp" className="d-none d-sm-block bg-image" alt=""/>
          <div className="mentor-x-carousel px-5">
                  <OwlCarousel className='owl-theme' {...options}>
                  <div className="mentor-card bg-skyblue">
                      <span className="mentor-icon m-icon-blue"><i className="fa-solid fa-sim-card"></i></span>
                      <h4 className="fw-bold text-center text-primary">International Sim</h4>
                  </div>
                  <div className="mentor-card bg-pink">
                      <span className="mentor-icon m-icon-pink"><i className="fa-solid fa-money-check-dollar"></i></span>
                      <h4 className=" fw-bold text-center text-primary">Credit Card</h4>
                  </div>
                  <div className="mentor-card bg-yellow">
                      <span className="mentor-icon m-icon-yellow"><i className="fa-solid fa-building-columns"></i></span>
                      <h4 className="fw-bold text-center text-primary">International Bank Account</h4>
                  </div>
                  <div className="mentor-card bg-skyblue">
                      <span className="mentor-icon m-icon-blue"><i className="fa-solid fa-money-bill-transfer"></i></span>
                      <h4 className=" fw-bold text-center text-primary">International Money Transfer </h4>
                  </div>
                  <div className="mentor-card bg-yellow">
                      <span className="mentor-icon m-icon-yellow"><i className="fa-solid fa-city"></i></span>
                      <h4 className="fw-bold text-center text-primary">Housing</h4>
                  </div>
                </OwlCarousel>
              <button type="button" className="m-owl-prev"><i className="fa-solid fa-chevron-left"></i></button>
              <button type="button" className="m-owl-next"><i className="fa-solid fa-angle-right"></i></button>
          </div>
      </div>
    </div>
  </section>
    </>
  )
}
