import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            <footer className="mt-4">
                <section className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h1><Link to="/"><img src="./assets/img/logo-footer.webp" width="140px" /></Link> </h1>
                                <div className="social-icons-btn">
                                    <a className="icons twitter" href="https://twitter.com/thestudent_help">
                                        <i className="fa-brands fa-twitter"></i>
                                    </a>
                                    <a className="icons facebook" href="https://www.facebook.com/TheStudentHelpline/">
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                    <a className="icons instagram" href="https://www.instagram.com/thestudenthelplineofficial/">
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>
                                    <a className="icons linkedin" href="https://au.linkedin.com/company/the-student-helpline">
                                        <i className="fa-brands fa-linkedin-in"></i>
                                    </a>
                                </div>
                                <br/>
                                    <p className="mb-1">
                                        <span><i className="fa-solid fa-phone fa-beat"></i></span>&nbsp;<a href="tel:+919318325049">9318325049</a>
                                    </p>
<br/>
<br/>
                                    <p className="mb-0"><span><i className="fa-solid fa-envelope fa-flip"></i></span>&nbsp;<a className="mail-to" href="mailto:help@learnwithfraternity.com">help@learnwithfraternity.com</a></p>
                            </div>

                            <div className="col-lg-4">
                                <h4 className="footer-title">Quick Links</h4>
                                <ul className="footer-list">
                                    <li><Link to="#">AI Tools</Link></li>
                                    <li><Link to="/about">About us</Link></li>
                                    <li><Link to="/contact">Contact us</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-4">
                                <h4 className="footer-title">Address</h4>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr className="mb-2">
                                            <td className="p-1"><img src="./assets/img/in.svg" width="40px" className="pt-2"/></td>
                                            <td className="p-1"><p className="px-2 mb-0 text-light"><strong>India</strong> <br/>Learn With Fraternity Pvt. Ltd.
                                                D-62,&nbsp;Noida,&nbsp;Sector 2</p></td>
                                        </tr>
                                        <tr className="mb-2">
                                            <td className="p-1"><img src="./assets/img/in.svg" width="40px" className="pt-2"/></td>
                                            <td className="p-1"><p className="px-2 mb-0 text-light"><strong>India</strong> <br/>Chrysler Tech Centre, Doon IT Park, Govind Vihar, Dehradun, Uttarakhand</p></td>
                                        </tr>
                                        <tr className="mb-2">
                                            <td className="p-1"><img src="./assets/img/sg.svg" width="40px" className="pt-2"/></td>
                                            <td className="p-1"><p className="px-2 mb-0 text-light"><strong>Singapore</strong> <br/>
                                                8-1218 Block, 467 Hougang Ave 8, Singapore</p></td>
                                        </tr>
                                        <tr className="mb-2">
                                            <td className="p-1"><img src="./assets/img/au.svg" width="40px" className="pt-2"/></td>
                                            <td className="p-1"><p className="px-2 mb-0 text-light"><strong>Australia</strong> <br/>
2 Peebles ST, Endeavour Hills Vicoria</p></td>
                                        </tr>
                                        <tr className="mb-2">
                                            <td className="p-1"><img src="./assets/img/ca.svg" width="40px" className="pt-2"/></td>
                                            <td className="p-1"><p className="px-2 mb-0 text-light"><strong>Canada</strong> <br/>
90 Park Lawn Rd, Etobicoke- Ontario</p></td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                        </div>
                        <div className="text-center copyright"><p>@2023 <Link to="/">Learn With Fraternity</Link> ALL RIGHTS RESERVED</p></div>
                    </div>
                </section>
            </footer>
        </>
    )
}
