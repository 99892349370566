import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';

export default function Navbar() {
        const [isNavigationActive, setNavigationActive] = useState(false);

        const handleHamburgerClick = (e) => {
          e.preventDefault();
          console.log(e.target)
          setNavigationActive((prevState) => !prevState);
        };    
        
    return (
    <>
          <header>
              <div className="logo">
                  <Link to="/"><img src="./assets/img/studentlogo.png" width="140px" alt=""/></Link>
              </div>
              <nav className={`navigation ${isNavigationActive ? 'active' : ''}`}>
                  <ul>

                      <li><Link to="#form">Course Finder</Link></li>
                      <li><Link to="/blog" >Blog</Link></li>
                      <li><Link to="#"><button className="loginbtn">AI Tools</button></Link></li>

                  </ul>
              </nav>
              <div className="hamburger" onClick={handleHamburgerClick}>
                  <div className="line line1"></div>
                  <div className="line line2"></div>
                  <div className="line line3"></div>
              </div>
          </header>
    </>
  )
}
