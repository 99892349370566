import React from 'react'
import Navbar from '../Navbar'
import { Link } from 'react-router-dom'

export default function Contact() {
  return (
    <>
    <div className="container blog-banner">
        <Navbar/>
            <h2 className="text-center fw-bolder fs-1">Contact US</h2>
            <p className="mb-1 text-secondary text-center">Our Friendly Team Love To Hear From You</p>
        </div>


    <section className="container px-lg-5">
        <div className="contact-container p-lg-3 m-2">
            <div className="row p-4">
                <div className="col-md-6 pe-3">
                    <form action="">
                        <div className="row g-3 mb-3">
                            <div className="col">
                                <label className="form-label">First Name</label>
                                <input type="text" className="form-control" placeholder="First name" aria-label="First name"/>
                            </div>
                            <div className="col">
                              <label className="form-label">Last Name</label>
                              <input type="text" className="form-control" placeholder="Last name" aria-label="Last name" />
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" id="formGroupExampleInput" placeholder="abs@yourcomapny.com"/>
                          </div>
                          <label for="" className="form-label">Phone Number</label>
                          <div className="input-group mb-3">
                            <input type="tel" className="form-control" placeholder="Enter Your Phone Number" name="phoneNumber" id="phone-input"/>
                          </div>
                          <div className="mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Email address</label>
                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
                          </div>
                          <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">Example textarea</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                            <label className="form-check-label" for="flexCheckChecked">
                              Checked checkbox
                            </label>
                          </div>
                          <button type="submit" className="text-center btn w-100 py-2 mt-4">Send Message</button>
                    </form>
                </div>
                <div className="col-md-6 ps-lg-5">
                    <div className="container">
                        <div className="mb-5">
                            <span className="text-primary fs-4"><i className="fa-regular fa-envelope"></i></span>
                            <h6 className="fw-bold mb-1 mt-2">Email</h6>
                            <p className="text-secondary mb-0"><small>Our friendly team is here to help</small></p>

                           <Link className="mail-to text-primary" to="mailto:help@learnwithfraternity.com">help@learnwithfraternity.com</Link>
                        </div>
                        <div className="mb-5">
                            <span className="text-primary fs-4"><i className="fa-solid fa-phone"></i></span>
                            <h6 className="fw-bold mb-1 mt-2">Phone</h6>
                            <p className="text-secondary mb-0"><small>Mon-Fri from 8am to 5pm.</small></p>
                           <a href="tel:919318325049">919318325049</a>
                        </div>
                        <div className="mb-5">
                            <span className="text-primary fs-4"><i className="fa-solid fa-location-dot"></i></span>
                            <h6 className="fw-bold mb-1 mt-2">Office</h6>
                            <p className="text-secondary mb-0"><small>Learn With Fraternity Pvt. Ltd.
					    D-62,&nbsp;Noida,&nbsp;Sector 2</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
