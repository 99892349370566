import React from 'react'

export default function Assistance() {
  return (
    <>
        <section className="services">
        <div className="container">
            <h2 className="title">Ensuring you get the best <br/> <span>Assistance</span></h2>
            <div className="circle-outer">
                <div className="circle-icons">
                    <img src="./assets/img/message.webp" alt=""/>
                </div>
                <div className="circle-icons">
                    <img src="./assets/img/message.webp" alt=""/>
                </div>
                <div className="circle-icons">
                    <img src="./assets/img/air.webp" alt=""/>
                    <img src="./assets/img/air.webp" alt=""/>
                </div>
                <div className="circle-icons">
                    <img src="./assets/img/air.webp" alt=""/>
                </div>
                <div className="circle">
                    <div className="circle-img">
                        <img src="./assets/img/boy.webp" alt="image"/>
                    </div>
                    <div className="circle-card">
                        <div className="circle-card-inner">
                             <h4 className="card-heading">IELTS</h4>
                            <p className="card-content">Receive personalized assistance from our in-house IELTS experts.</p>
                            <div className="btn btn-primary"><a href="/ielts">Free Assistance</a></div>
                            <div className="bg-img">
                                <img src="./assets/img/box.webp" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="circle-card caed-tb">
                        <div className="circle-card-inner">
                             <h4 className="card-heading">Universities</h4>
                            <p className="card-content">Use our Course Finder to find your dream university!</p>
                            
                            <div className="d-flex justify-content-between">
                                <div className="btn btn-primary"><a href="./form">Check University</a></div>
                            <div className="bg-img">
                                 <img src="./assets/img/book.webp" alt=""/>
                            </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="circle-card">
                        <div className="circle-card-inner">
                             <h4 className="card-heading">Student Accommodation</h4>
                            <p className="card-content">Book Accommodation Near Your Top Global Universities</p>
                            <div className="btn btn-primary"><a href="./form"> Check Accommodation </a></div>
                            <div className="bg-img">
                               <img src="./assets/img/buliding.webp" alt=""/>
                            </div>
                            
                        </div>
                    </div>
                    <div className="circle-card">
                        <div className="circle-card-inner">
                            <h4 className="card-heading">Apply Scholarship</h4>
                            <p className="card-content">Discover the Top Scholarships That Match Your Profile</p>
                            <div className="btn btn-primary">Find Scholarships</div>
                            <div className="bg-img">
                                <img src="./assets/img/cap.webp" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="circle-card">
                        <div className="circle-card-inner">
                            <h4 className="card-heading">Education Loan</h4>
                            <p className="card-content">Explore your loan eligibility for Studying Abroad</p>
                            <div className="btn btn-primary"><a href="./form">Book Experts</a></div>
                            <div className="bg-img">
                                  <img src="./assets/img/calc.webp" alt=""/>
                              
                            </div>
                           
                        </div>
                    </div>
                    <div className="circle-card">
                        <div className="circle-card-inner">
                            <h4 className="card-heading">Visa Application</h4>
                            <p className="card-content">World-Class Visa Assistance at Your Fingertips</p>
                            <div className="btn btn-primary"><a href="./form">Apply Visa</a></div>
                            <div className="bg-img">
                                <img src="./assets/img/visa.webp" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
