 import React from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Home from './Home'
import Footer from './Footer'
import Navbar from './Navbar'
import Blog from './Blog/Blog'
import Ielts from './Ielts/Ielts'
import Contact from './Contact/Contact'
import About from './About/About'

export default function App() {
  return (
    <>
        <BrowserRouter >
            {/* <Navbar/> */}
            <Routes>
                <Route path='' element={<Home/>} />
                <Route path='/blog' element={<Blog/>}/>
                <Route path='/ielts' element={<Ielts/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/about' element={<About/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    </>
  )
}
