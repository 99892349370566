import React from 'react'
import BlogCard from './Blog/BlogCard'
import { useEffect,useState } from 'react'

export default function Blog() {
    var [blogs,setBlogs] = useState([])
    async function getBlogData(){
        var response = await fetch("http://localhost:5000/blog",{
            method:"get",
            headers:{
                "content-type":"application/json"
            }
        })
        const data = await response.json()
        if(data.length){
            setBlogs(data)
        }
    }
    useEffect(()=>{
        getBlogData()
    },blogs.length)
    return (
        <>
            <section className="blog mt-4">
                <div className="container">
                    <div className="blog-container">
                        <h6 className="sub-title">Our Latest Blog</h6>
                        <h2 className="title">Hear From Our Students</h2>

                        <button className="blog-btn p-0">
                            <a href="https://thestudenthelpline.co.in/blog" className="primary-btn py-3 px-4 rounded-pill text-white">
                                See All
                            </a>
                        </button>
                        <div className="blog-boxes">
                            {
                                blogs.map((item, index) => {
                                    return <BlogCard key={index} link={item.link} img={item.img} title={item.title} time={item.time} content={item.content} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
