import React from 'react'

export default function Faqs() {
    return (
        <>
            <section>
                <div className="container mt-5">
                    <h2 className="fs-1 mb-4 fw-600">FAQs</h2>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span className="accordion-numb">1</span> How Does TheStudentHelpline Help You?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    TheStudentHelpline is a platform designed to provide comprehensive assistance and supporting students at every stage of their educational journey and helping them achieve their academic and career goals.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span className="accordion-numb">2</span> What are the Benefits of Studying Abroad with TheStudentHelpline?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Studying abroad with TheStudentHelpline offers global exposure, high-quality education, personal growth, and expanded career prospects. It is a transformative experience that enriches lives and creates lifelong memories.
                                    <ul><li><b>Expert Guidance:</b> Knowledgeable counselors can guide through admission processes and course selection.</li>
                                        <li><b>Scholarships:</b> They may help you find and apply for scholarships.</li>
                                        <li><b>Accommodation Help:</b> Assistance with finding safe and reliable accommodation.</li>
                                        <li><b>Visa Assistance:</b> Guidance on visa application processes.</li>
                                        <li><b>Pre-departure Briefings:</b> Useful tips and advice before you travel.</li>
                                        <li><b>Global Perspective:</b> Studying abroad gives you an international perspective, helping you understand and adapt to different cultures and working styles.</li></ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span className="accordion-numb">3</span> How TheStudentHelpline Can Enhance Your Career Prospects Through Studying Abroad?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    TheStudentHelpline can enhance your career prospects through studying abroad by providing access to high-quality education, global exposure, language proficiency, and a diverse network of connections. This international experience makes you more adaptable, culturally aware, and attractive to employers in today's global job market.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <span className="accordion-numb">4</span>Study Abroad Scholarship Application Support at TheStudentHelpline?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    At TheStudentHelpline, we offer comprehensive support for study abroad scholarship applications. Our experienced team assists students in navigating the application process, providing guidance on required documents, crafting compelling essays, and showcasing their achievements effectively to increase their chances of securing scholarships.
                                    <ul><li><b>Information:</b> Providing information about available scholarships and their eligibility criteria.</li>
                                    <li><b>Documentation:</b> Assisting with document preparation, ensuring that all necessary paperwork is correctly filled out and submitted on time.</li>
                                    <li><b>Essays:</b> Guiding you in writing effective scholarship essays or personal statements.</li>
                                    <li><b>Application Process:</b> Helping you understand the application process and deadlines.</li>
                                    <li><b>Follow-up:</b> Assisting with follow-up communication with scholarship bodies.</li></ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span className="accordion-numb">5</span> How TheStudentHelpline Can Help You Secure Funding for Your Studies?
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                TheStudentHelpline can help you secure funding for your studies through our expertise in scholarship applications, financial aid guidance, and access to various funding opportunities. We assist students in identifying suitable scholarships, crafting compelling applications, and maximizing their chances of receiving financial support for their educational journey.
                                <ul><li><b>Scholarship Information:</b> They can provide information about available scholarships and help you apply for them.</li>
                                    <li><b>Student Loans:</b> They might assist with information on how to secure student loans, including explaining terms and conditions.</li>
                                    <li><b>Financial Planning:</b> They can guide you in planning your finances for your study abroad, helping you estimate costs and budget effectively.</li>
                                    <li><b>Part-Time Work:</b> They might assist with information on part-time work opportunities in the host country.</li>
                                    <li><b>Funding Events:</b> They could host or inform you about events, workshops, or webinars about funding education abroad.</li></ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                <span className="accordion-numb">6</span> How TheStudentHelpline Can Assist You With Study Abroad Visa Application?
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                TheStudentHelpline provides comprehensive assistance with study abroad visa applications. Our experienced team guides students through the visa process, ensuring they have the necessary documents, meet eligibility requirements, and submit a complete application. We offer support and advice to help students navigate any challenges that may arise during the visa application process, making the process smoother and increasing the likelihood of obtaining a study abroad visa successfully.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                <span className="accordion-numb">7</span>How TheStudentHelpline Offers Support and Guidance of Profile Building For Studying Abroad?
                            </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                At TheStudentHelpline, we offer expert support and guidance for profile building to help students enhance their chances of studying abroad. Our team assists in creating a strong academic and extracurricular profile, showcasing achievements, leadership skills, and relevant experiences. We provide personalized advice on improving resumes, crafting compelling statements of purpose, and building a well-rounded profile that stands out to potential universities, increasing the likelihood of admission to their desired study abroad programs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section >

    </>
  )
}
