import React from 'react'
import Navbar from '../Navbar'
import { Link } from 'react-router-dom'

export default function About() {
  return (
    <>
     <div className="container blog-banner">
            <Navbar/>
            <h1 className="text-center fw-bolder">About US</h1>
        </div>
   
    
  
    <section className="my-5">  
        <div className="container">
            <div className="row">
                    <div className="col-lg-7 pt-5">
                        <span className="about-title fw-600 fs-3 px-3 py-2">Our Story</span>
                        <p className="mt-4">Born from its founders' personal experiences navigating the complexities of studying abroad, The Student Helpline emerged as a beacon of support for students globally. Recognizing the challenges associated with international education, they developed a service dedicated to guiding students through these hurdles with personalized assistance.</p>
                        <p>Today, The Student Helpline, powered by a team of committed professionals, offers a comprehensive suite of services to demystify studying abroad. With the trust of over a million students worldwide, it stands as a testament to resilience and commitment, transforming dreams into reality, one student at a time.</p>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-around flex-wrap  ">
                        <img src="./assets/img/Rectangle 16.webp" className="mb-3" alt="" />
                        <img src="./assets/img/Rectangle 15.webp" className="mb-2" alt="" />
                        <img src="./assets/img/Rectangle 14.webp" className="mb-2" alt="" />
                    </div>
            </div>
        </div>
    </section>


    <section className="my-5">
        <div className="container">
            <div className="course-tab p-5">
                <span className="about-title fw-600 fs-3 px-3 py-2">Our Mission</span>
                <div className="d-flex mt-5 p-lg-4">
                    <div className="vr-line-1 d-none d-md-block"></div>
                    <div className="ms-lg-5">
                        <p className="text-secondary fs-small">The Student Helpline is committed to guiding students globally on their journey to academic success abroad. With a mission to provide personalized and comprehensive support, they aim to demystify the complex processes of securing admissions, scholarships, and visas. They strive to empower students, enabling them to make informed decisions and fulfill their educational aspirations.</p>
                        <p className="text-secondary fs-small">Beyond just assisting with logistical processes, The Student Helpline seeks to shape the overall growth of the students. By helping them build strong profiles and offering valuable insights from accomplished peers, they aim to foster a community of globally competent, confident individuals ready to excel in their chosen fields.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section className="my-5 bg-gradient-cr">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <img src="./assets/img/Image (1).webp" className="img-fluid" alt="" />
                </div>
                <div className="col-lg-5 ">
                    <h3 className="fw-bold my-4">What Do We Do & Why Do We Do</h3>
                    <p className="mb-3 text-secondary">The Student Helpline is an all-encompassing platform dedicated to providing guidance and assistance to students aiming to study abroad. We offer personalized support throughout the process, including choosing the right course and university, application assistance, visa processing, and profile building. Our services extend to securing scholarships and arranging accommodations, thereby ensuring students have a smooth transition to their new academic journey. The guidance from our team of experts, combined with insights from accomplished students, empowers individuals to navigate their academic pathway with confidence.</p>
                    <a href="" className="banbtn2 text-white fs-6 mx-auto px-3">Get Started</a>
                </div>
            </div>
        </div>

      
        <div className="container my-5 pt-lg-5">
            <div className="row">
                <div className="col-lg-5">
                  <img src="./assets/img/Image (2).webp" className="img-fluid" alt="" />  
                </div>
                <div className="col-lg-7 ">
                    <h3 className="fw-bold mb-4">How The Student Helpline Works</h3>
                    <p className="text-secondary">The Student Helpline garnered the trust of students around the globe. They were drawn by the authenticity of guidance, the robust support system, and the dedicated efforts to ensure each student reached their academic potential. Today, The Student Helpline has helped over a million students achieve their dreams of studying abroad.</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
