import React from 'react'

export default function Media() {
  return (
    <>
        <section>
<div className="container">
<div className="mediasec1">
<div className="d-flex flex-wrap mb-3 text-center justify-content-around">
<div className="p-2"><div className="media-div p-2 mdiv5"><img src="assets/img/businessrev.webp" width="100%" alt=""/></div> </div>
<div className="p-2"><div className="media-div p-2 mdiv6"><img src="assets/img/newsdirect.webp" width="100%" alt=""/></div> </div>
<div className="p-2"><div className="media-div p-2 mdiv7"><img src="assets/img/european.webp" width="100%" alt=""/></div> </div>
<div className="p-2"><div className="media-div p-2 mdiv8"><img src="assets/img/outlook.webp" width="100%" alt=""/></div> </div>
<div className="p-2"><div className="media-div p-2 mdiv9"><img src="assets/img/apn.webp" width="100%" alt=""/></div> </div>
<div className="p-2"><div className="media-div p-2 mdiv10"><img src="assets/img/marketwatch.webp" width="100%" alt=""/></div> </div>
<div className="p-2"><div className="media-div p-2 mdiv11"><img src="assets/img/deccan.webp" width="100%" alt=""/></div> </div>
</div>
</div>
</div>
</section>
    </>
  )
}
