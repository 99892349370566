import React from 'react'
import Navbar from '../Navbar'
import { Link } from 'react-router-dom'

export default function Ielts() {
  return (
    <>
        <div className="banner h-100">
            <Navbar/>
    
        <div className="container">
            <div className="row">
                <div className="col-lg-6 d-flex align-items-center">
                    <div className="bannerleftsec">
                        <h1>Get a perfect score <span><svg xmlns="http://www.w3.org/2000/svg" width="218" height="22" viewBox="0 0 218 22" fill="none">
                            <path d="M3 19.0369C44.7146 4.27994 103.5 2.70991 128.5 3.03692C159.5 3.44241 193 8.03696 215 12.9149" stroke="#F4AC1F" strokeWidth="5" strokeLinecap="round"/>
                            </svg></span><br/>on IELTS
                        </h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum massa sit amet porta
                            efficitur.
                        </p>
                        <div className="banleft-bottom">
                           <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <div className='my-4'>
                            <a href="https://thestudenthelpline.co.in/form" class="primary-btn py-3 px-4 text-white fw-600 rounded-pill mt-2">
                                Find Your Dream University
                            </a>
                            </div>
                           </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="banner-right-sec">
                        <img src="./assets/img/positive-teenagers-posing-together-university-removebg-preview.webp" className="img-fluid" style={{height:"unset"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>



    
    <section className="mt-4">
        <div className="container-xxl">
            <h2 className="fs-1 fw-600 ms-5 mb-5">Why The Student Helpline</h2>
            <div className="row gap-5 justify-content-center">
                <div className="col-lg-4 col-md-5">
                    <div className="why-card p-5 mb-4">
                        <img src="./assets/img/assurance.webp" alt="" />
                        <p className="text-center">Professional and certified expert trainers always their for you.</p>
                    </div>
                    <div className="why-card p-5 mb-4">
                        <img src="./assets/img/meter.webp" alt="" />
                        <p className="text-center">Boost your chances of getting the IELTS score you deserve.</p>
                    </div>
                </div>
                <div className="col-lg-3 d-none d-lg-block">
                    <img src="./assets/img/girlwithlap.webp" className="" alt="" />
                </div>
                <div className="col-lg-4 col-md-5">
                    <div className="why-card p-5 mb-4">
                        <img src="./assets/img/chechklist.webp" alt="" />
                        <p className="text-center">IELTS sectional and practice test questions to get you ready for exam.</p>
                    </div>
                    <div className="why-card p-5 mb-4">
                        <img src="./assets/img/messages.webp" alt="" />
                        <p className="text-center">Additional IELTS Practice Materials include test format.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section className="mt-4">
        <div className="container">
           
            <div className="tst-header d-flex justify-content-between mb-4">
                <div>
                    <h2 className="fs-1 fw-600">The Student Helpline Insights For Your Study Abroad Journey</h2>
                </div>
                <div className="d-flex align-items-center owl-nav">
                    <button type="button" className="tst-nav me-4 p-owl-prev" role="presentation"><i className="fa-solid fa-chevron-left"></i></button>
                    <button className="tst-nav p-owl-next"><i className="fa-solid fa-angle-right"></i></button>
                </div>
           </div>
           <div className="owl-carousel owl-theme" id="program-carousel">
            <div className="program-card bg-purple p-5">
                <img src="./assets/img/monitor.webp" className="mb-3" width="250px" alt="" />
                <h2 className="fs-4 mb-3 text-purple">Experienced Instructors</h2>
                <p className="text-secondary">We boasts a team of experienced and highly knowledgeable IELTS instructors who are well-versed with the exam format, content, and scoring criteria.</p>
            </div>
            <div className="program-card bg-green-1 p-5">
                <img src="./assets/img/conver.webp" className="mb-3" width="250px" alt="" />
                <h2 className="fs-4 mb-3 text-green-1">Personalized Attention</h2>
                <p className="text-secondary">We prioritize individual attention, and our small className sizes allow our instructors to address your specific learning needs effectively.</p>
            </div>
            <div className="program-card bg-pink-1 p-5">
                <img src="./assets/img/books.webp" className="mb-3" width="250px" alt="" />
                <h2 className="fs-4 mb-3 text-pink-1">Extensive Practice</h2>
                <p className="text-secondary">Practice is essential for exam preparation, The Student Helpline provides ample opportunities for practice through mock tests, questions, and practice papers.</p>
            </div>
            <div className="program-card bg-pink-1 p-5">
                <img src="./assets/img/growthf.png" className="mb-3" width="250px" alt="" />
                <h2 className="fs-4 mb-3 text-pink-1">Proven Track Record of Success</h2>
                <p className="text-secondary">The Student Helpline has a proven track record of guiding students to achieve remarkable success in the IELTS exam.</p>
            </div>
            <div className="program-card bg-pink-1 p-5">
                <img src="./assets/img/books.webp" className="mb-3" width="250px" alt="" />
                <h2 className="fs-4 mb-3 text-pink-1">Flexible Learning Options</h2>
                <p className="text-secondary">Choose from in-person and online training options, accommodating your schedule and preferences for a convenient learning experience.</p>
            </div>
           </div>
   
        </div>
    </section>

 
    <section className="mt-4">
        <div className="container">
            <p className="fw-bold mb-4 text-warning">About IELTS</p>
            <div className="row box-gradient p-4">
                <div className="col-md-6 pt-5 ">
                    <h5 className="mb-4">What is IELTS</h5>
                    <p>The International English Language Testing System (IELTS) is a standardized test used to assess the English language proficiency of individuals who wish to study or work in English-speaking countries. Recognized by over 10,000 organizations worldwide, IELTS is one of the most widely accepted English language proficiency tests.</p>
                    <a href="https://thestudenthelpline.co.in/form" className="primary-btn py-2 px-3 rounded-pill">SEE ALL</a>
                </div>
                <div className="col-md-6">
                    <img src="./assets/img/about-banner.webp" className="img-fluid" alt="" />
                </div>  
            </div>
        </div>
    </section>
    
   
    <section className="mt-4">
        <div className="container">
            <p className="fw-bold text-warning mb-4">Type of IELTS</p>
            <div className="row justify-content-between">
                <div className="col-md-6">
                    <div className="d-flex box-gradient mb-2 me-lg-4 p-4">
                        <div className="vr-line me-4"></div>
                        <div>
                            <p className="fw-bold">Academic</p>
                            <p><small>The IELTS Academic test is an essential gateway for individuals aspiring to pursue higher education in English-speaking countries. Recognized and accepted by thousands of universities and educational institutions worldwide. We're devoted to helping you reach your educational goals, providing the best advice and materials for acing IELTS and getting into your ideal college.</small></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="d-flex box-gradient mb-2 me-lg-4 p-4">
                        <div className="vr-line me-4"></div>
                        <div>
                            <p className="fw-bold">General</p>
                            <p><small>The IELTS General Training test is your gateway to new opportunities in English-speaking countries. Whether you dream of working abroad, seeking international training programs, or migrating to a new land, it is designed to assess your practical language skills in everyday contexts. At The Student Helpline, we offer IELTS General Training to empower you with the language proficiency required to achieve your global aspirations.</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="mt-4">
        <div className="container-xxl">
            <p className="text-warning mb-4">Academic Test Format</p>
            <div className="row  justify-content-between">
                <div className="col-lg-4 col-md-5 academic-section">
                    <div className="academic-card bg-purple px-4 py-3 mb-4">
                        <img src="./assets/img/headphone.webp" alt="" />
                        <div className="ms-3">
                            <p className="fs-6 fw-600 mb-1 text-purple">Listening</p>
                            <p className="mb-0"><small>Enhance your ability to comprehend spoken English in various contexts, such as lectures, conversations, and presentations.</small></p>
                        </div>
                    </div>
                    <div className="academic-card bg-green-1 px-4 py-3 mb-4">
                        <img src="./assets/img/mic.webp" alt="" />
                        <div className="ms-3">
                            <p className="fs-6 fw-600 mb-1 text-green-1">Speaking</p>
                            <p className="mb-0"><small>Build confidence in expressing yourself fluently and coherently in both informal and formal situations.</small></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 d-none d-lg-block">
                    <img src="./assets/img/girlwithbook.webp" className="academic-card-img" alt="" />
                </div>
                <div className="col-lg-4 col-md-5 academic-section">
                    <div className="academic-card bg-pink-1 px-4 py-3 mb-4">
                        <img src="./assets/img/pencil.webp" alt="" />
                        <div className="ms-3">
                            <p className="fs-6 fw-600 mb-1 text-pink-1">Writitng</p>
                            <p className="mb-0"><small>Improve your reading skills and learn techniques to comprehend complex passages effectively.</small></p>
                        </div>
                    </div>
                    <div className="academic-card bg-purple px-4 py-3 mb-4">
                        <img src="./assets/img/messages.webp" alt="" />
                        <div className="ms-3">
                            <p className="fs-6 fw-600 mb-1 text-purple ">Writitng</p>
                            <p className="mb-0"><small>Develop your writing skills for different tasks, such as essays, reports, and letters.</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="mt-4">
        <div className="container">
            <p className="text-warning mb-4 fw-600">Acceptability</p>
        <div className="box-gradient py-2">
            <div className="row px-5">
                <div className="col-md-4 col-sm-6 d-flex mb-3">
                    <img src="./assets/img/aus.webp" alt="" />
                    <p className="fs-4 fw-600 ms-3">New Zealand</p>
                </div>
                <div className="col-md-4 col-sm-6 d-flex mb-3">
                    <img src="./assets/img/usa.webp" alt="" />
                    <p className="fs-4 fw-600 ms-3">United States</p>
                </div>    
                <div className="col-md-4 col-sm-6 d-flex mb-3">
                    <img src="./assets/img/uk (2).webp" alt="" />
                    <p className="fs-4 fw-600 ms-3">Australia</p>
                </div>    
                <div className="col-md-4 col-sm-6 d-flex mb-3">
                    <img src="./assets/img/france (2).webp" alt="" />
                    <p className="fs-4 fw-600 ms-3">Ireland</p>
                </div>    
                <div className="col-md-4 col-sm-6 d-flex mb-3">
                    <img src="./assets/img/canada.webp" alt="" />
                    <p className="fs-4 fw-600 ms-3">Canada</p>
                </div>    
                <div className="col-md-4 col-sm-6 d-flex mb-3">
                    <img src="./assets/img/flag.webp" alt="" />
                    <p className="fs-4 fw-600 ms-3">United Kingdom</p>
                </div>    
         
            </div>
        </div>
        </div>
    </section>        

    
    <section className="mt-4">
        <div className="container-fluid p-0">
          <div className="container">
                <h2 className="fw-600 ms-4">IELTS Programs Of The Student Helpline</h2>
          </div>
            <div className="box-gradient py-5 px-lg-5">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-md-5">
                            <div className="package-card mx-lg-5 bg-white border rounded">
                                <h2 className="fs-2 text-center mt-5">IELTS Premium Package</h2>
                                <hr className="fw-bolder" />
                                <h2 className="fs-2 text-center px-3">Batch Size Of 4-5 Students</h2>
                                <hr className="fw-bolder" />
                                <div className="my-5 px-5 pb-5">
                                    <p className="my-3 text-center fs-5 text-secondary">20+ Hours of live classNamees</p>
                                    <p className="my-3 text-center fs-5 text-secondary">4 Week Course</p>
                                    <p className="my-3 text-center fs-5 text-secondary">3-5 Full Length IELTS simulation Tests</p>
                                    <p className="my-3 text-center fs-5 text-secondary">50+ Reading and Listening Tests</p>
                                    <p className="my-3 text-center fs-5 text-secondary">25 Speaking and 30 Writing Evaluations</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Study Materials</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Grammar Improvement classNamees</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Doubt Solving Sessions – Everyday</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Dedicated Students Success Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mb-4">
                            <div className="package-card mx-lg-5 bg-white border rounded">
                                <h2 className="fs-2 text-center mt-5">IELTS Elite Package</h2>
                                <hr className="fw-bolder" />
                                <h2 className="fs-2 text-center">Batch Size Of 10-15 Students</h2>
                                <hr className="fw-bolder" />
                                <div className="my-5 px-5 pb-5">
                                    <p className="my-3 text-center fs-5 text-secondary">20+ Hours of live classNamees</p>
                                    <p className="my-3 text-center fs-5 text-secondary">4 Week Course</p>
                                    <p className="my-3 text-center fs-5 text-secondary">3 Full Length IELTS simulation Tests</p>
                                    <p className="my-3 text-center fs-5 text-secondary">30+ Reading and Listening Tests</p>
                                    <p className="my-3 text-center fs-5 text-secondary">15 Speaking and 20 Writing Evaluations</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Study Materials</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Grammar Improvement classNamees</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Doubt Solving Sessions – Twice In a Week</p>
                                    <p className="my-3 text-center fs-5 text-secondary">Dedicated Students Success Manager</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="https://thestudenthelpline.co.in/form" className="my-5 primary-btn py-2 px-3 rounded-pill">SEE ALL 
                    </a>
                </div>
            </div>
        </div>
    </section>
{/* 
<section className="mt-4">
<div className="container"> 
       <div className="tst-header d-flex justify-content-between mb-4">
            <div>
                <p className="text-warning fw-600 fs-5 mb-2">Testimonials</p>
                <h2 className="fs-1 fw-600">Students Choose And Recommend Us</h2>
            </div>
            <div className="d-flex align-items-center owl-nav">
                <button type="button" className="tst-nav me-4 prev" role="presentation"><i className="fa-solid fa-chevron-left"></i></button>
                <button className="tst-nav next"><i className="fa-solid fa-angle-right"></i></button>
            </div>
       </div>

       <div className="tst-body owl-carousel owl-theme" id="owlcustom">
            <div className="tst-frame">
            <video className="vid" poster="https://thestudenthelpline.co.in/assets/video/thumb/t3.webp" width="100%" >
                    <source src="https://thestudenthelpline.co.in/assets/video/t3.webm" />
            </video>
            <div  className="video-controls">
                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
            </div>
                        <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
            <div className="tst-frame-content">
                <p className="mb-0 fs-5">Jatin</p>
                <span><small>With their extensive expertise and unwavering support, your journey to international education will be smooth and rewarding</small></span>
            </div>
        </div>
                <div className="tst-frame">
            <video className="vid" poster="https://thestudenthelpline.co.in/assets/video/thumb/t2.webp" width="100%" >
                    <source src="https://thestudenthelpline.co.in/assets/video/t2.webm">
            </video>
            <div  className="video-controls">
                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
            </div>
                        <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
            <div className="tst-frame-content">
                <p className="mb-0 fs-5">Kansha</p>
                <span><small>The Student Helpline comes highly recommended. Their comprehensive support and guidance can make your global education dreams a reality</small></span>
            </div>
        </div>

        <div className="tst-frame">
            <video className="vid" poster="https://thestudenthelpline.co.in/assets/video/thumb/t6.webp" width="100%" >
                    <source src="https://thestudenthelpline.co.in/assets/video/t6.webm">
            </video>
            <div  className="video-controls">
                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
            </div>
                        <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
            <div className="tst-frame-content">
                <p className="mb-0 fs-5">Ruchita</p>
                <span><small>The Student Helpline comes highly recommended. Their comprehensive support and guidance can make your global education dreams a reality</small></span>
            </div>
        </div>
        <div className="tst-frame">
            <video className="vid" poster="./assets/video/thumb/t1.webp" width="100%" >
                    <source src="./assets/video/t1.webm">
            </video>
            <div  className="video-controls">
                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
            </div>
            <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
            <div className="tst-frame-content">
                <p className="mb-0 fs-5">Nidhi</p>
                <span><small>I strongly endorse The Student Helpline for anyone considering studying abroad</small></span>
            </div>
        </div>
        <div className="tst-frame">
            <video className="vid" poster="https://thestudenthelpline.co.in/assets/video/thumb/t4.webp" width="100%" >
                    <source src="https://thestudenthelpline.co.in/assets/video/t4.webm">
            </video>
            <div  className="video-controls">
                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
            </div>
                        <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
            <div className="tst-frame-content">
                <p className="mb-0 fs-5">Kartik</p>
                <span><small>The Student Helpline boasts an exceptional team with a strong commitment to professionalism and integrity</small></span>
            </div>
        </div>
        <div className="tst-frame">
            <video className="vid" poster="https://thestudenthelpline.co.in/assets/video/thumb/t5.webp" width="100%" >
                    <source src="https://thestudenthelpline.co.in/assets/video/t5.webm">
            </video>
            <div  className="video-controls">
                <button type="button"><i className="fa-solid fa-play text-white"></i></button>
            </div>
                        <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
            <div className="tst-frame-content">
                <p className="mb-0 fs-5">Ritik</p>
                <span><small>Had it not been for The Student Helpline, I wouldn't have been able to achieve my objectives</small></span>
            </div>
        </div>
    </div>
    </div>
</section> */}

  
    <section className="mt-4">
    <div className="container mt-5">
        <h2 className="fs-1 mb-4 fw-600">FAQs</h2>
        <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span className="accordion-numb">1</span>How Can I Study Abroad For Free?</button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">Students who want to study abroad for free, there is option as Scholarship available for you. You need to know the desire country and why you need the scholarship. Scholarships, especially fully funded scholarships, are one method to pursue higher education without breaking the bank.</div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span className="accordion-numb">2</span>Do All IELTS Courses Include Live Query Resolution?</button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">Yes, It is affirmative that apart from the in-className interactions, you can also join the daily doubt clearance sessions organized for all the programmes.</div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span className="accordion-numb">3</span>What Is The Duration Of The IELTS Program?</button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">Our IELTS program will offer live classNamees with an professional Trainer, 200+ Reading & Listening scored mock tests, full length speaking evaluations and generous writing evaluations for complete practice.</div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <span className="accordion-numb">4</span>How To Get A Scholarship To Study Abroad?</button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">Accessing potential study abroad scholarships can be done through many diverse pathways, such as university websites, internet scholarship databases, and official study abroad pages for individual countries. Popular scholarships include academic, merit-based, need-based, athletic-based, area or minority-based, and more.</div>
                </div>
            </div>
             <div className="accordion-item mb-5">
                <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <span className="accordion-numb">5</span>How Long Is The Ielts Test Score Valid?</button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">The IELTS results are valid for two years from the result declaration date, but it also depends on the organization to set their validity for different purposes.</div>
                </div>
            </div>
        </div>
      
    </div>
    </section>
    
  
   
    </>
  )
}
