import React from 'react'
import Navbar from './Navbar'
import Testimonials from './Testimonials'
import Assistance from './Assistance'
import Cta1 from './Cta1'
import Cta2 from './Cta2'
import Media from './Media'
import Faqs from './Faqs'
import Reviews from './Reviews'
import Mentor from './Mentor'
import Blog from './Blog'


export default function Home() {
    return (
        <>
            <div className="banner">
                <Navbar />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="bannerleftsec">
                                <div className="my-5">
                                    <button className="banbtn1">The Student Helpline</button>
                                </div>
                                <h1>Study Abroad With Skill <span><svg xmlns="http://www.w3.org/2000/svg" width="218" height="22" viewBox="0 0 218 22" fill="none">
                                    <path d="M3 19.0369C44.7146 4.27994 103.5 2.70991 128.5 3.03692C159.5 3.44241 193 8.03696 215 12.9149" stroke="#F4AC1F" strokeWidth="5" strokeLinecap="round" />
                                </svg></span>Everyday, Anytime, and Anywhere.</h1>

                                <p>TheStudentHelpline brings a fresh approach to achieve your study abroad dream - <b>Transparent, Affordable, and 100% Unbiased</b></p>
                                <div className="banleft-bottom">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div>

                                            <button className="banbtn2"><a href="<?php echo site_url(); ?>form">FInd your dream University <i className="fa-regular fa-circle-right"></i></a></button>
                                        </div>
                                        <div id="rimges">
                                            <img src="assets/img/r1.webp" />
                                            <img src="assets/img/r2.webp" className="rate-img" />
                                            <img src="assets/img/r3.webp" className="rate-img" />
                                        </div>
                                        <div className="banrate">
                                            <p className="mb-1 fs-2" id="number2"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i className="fa-regular fa-star-half-stroke"></i></p><span>(10k+ Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="banner-right-sec d-flex justify-content-center">
                                <img src="assets/img/banner-img.webp" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonials/>
            <Assistance/>
            <Cta1/>
            <Cta2/>
            <Mentor/>
            <Blog/>
            <Media/>
            <Faqs/>
            <Reviews/>
        </>
    )
}
